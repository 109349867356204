import { Checkbox, Select, Space } from "ebs-design";

import { nextValue } from "utils";
import { UseFiltersResult } from "hooks";
import { FilterType } from "types";

import { Icon } from "./Icon";
import { DebouncedInput } from "./DebouncedInput";
export interface FiltersProps {
  filtersResult: UseFiltersResult;
}

export const Filters = ({
  filtersResult: { activeFiltersValues, setActiveFiltersValues, filters },
}: FiltersProps) => {
  return (
    <>
      <div className="filters">
        {filters.map((filter) => {
          let ret = null;
          if (filter.type === FilterType.boolean) {
            ret = (
              <Checkbox
                indeterminate={activeFiltersValues[filter.key] === "false"}
                checked={activeFiltersValues[filter.key] === "true"}
                onChange={() =>
                  setActiveFiltersValues((current) => ({
                    [filter.key]: nextValue(
                      ["true", "false", undefined],
                      current[filter.key],
                    ),
                  }))
                }
                text={filter.name}
              />
            );
          } else if (filter.type === FilterType.enum) {
            ret = (
              <Select
                isClearable={!!activeFiltersValues[filter.key]}
                placeholder={filter.name}
                options={filter.values}
                value={activeFiltersValues[filter.key] || undefined}
                loading={filter.isLoading}
                onChange={(value) => {
                  setActiveFiltersValues({
                    [filter.key]: String(value || "") || undefined,
                  });
                }}
              >
                {filter.onSearch && (
                  <Select.Search onSearch={filter.onSearch} />
                )}

                <Select.Pagination
                  count={filter?.count || 0}
                  limit={filter?.filters?.limit || 0}
                  page={filter?.filters?.page || 0}
                  setPage={(page) =>
                    filter?.setFilters && filter?.setFilters(page)
                  }
                  mode="scroll"
                />
              </Select>
            );
          } else if (filter.type === FilterType.numberRange) {
            const value = activeFiltersValues[filter.key];
            const [smin, smax] = value?.split("__") || ["", ""];

            ret = (
              <Space size={8}>
                {(smin || smax) && (
                  <Icon
                    className="cursor-pointer"
                    type="cross"
                    onClick={() =>
                      setActiveFiltersValues({ [filter.key]: undefined })
                    }
                  />
                )}
                {filter.name}
                <DebouncedInput
                  type="number"
                  placeholder="min"
                  onChange={(v) => {
                    setActiveFiltersValues({
                      [filter.key]: `${Number(v)}__${Math.max(
                        Number(v),
                        Number(smax),
                      )}`,
                    });
                  }}
                  value={smin}
                  min={filter.min}
                  max={filter.max}
                />
                -
                <DebouncedInput
                  type="number"
                  placeholder="max"
                  onChange={(v) =>
                    setActiveFiltersValues({
                      [filter.key]: `${Math.min(
                        Number(v),
                        Number(smin),
                      )}__${Number(v)}`,
                    })
                  }
                  value={smax}
                  min={filter.min}
                  max={filter.max}
                />
              </Space>
            );
          }
          return (
            <div key={filter.key} className="filters__item">
              {ret}
            </div>
          );
        })}
      </div>
    </>
  );
};
