import { useSetState } from "react-use";
import { useQuery } from "react-query";
import { Select } from "ebs-design";

import { categories } from "api";
import { AxiosError } from "axios";
import { Results, CategoryEntity } from "types";
import { defaultFilters, mapToSelectOptions } from "utils";

export interface SubcategorySelectProps {
  categoryId: number;
  value: number[];
  onChange: (v: number[]) => void;
}

export const SubcategorySelect = ({
  categoryId,
  value,
  onChange,
}: SubcategorySelectProps) => {
  const [filters, setFilters] = useSetState(defaultFilters);

  const { data, isLoading } = useQuery<Results<CategoryEntity>, AxiosError>(
    ["categories", categoryId, "children", filters],
    () => categories.getChildren(categoryId, filters),
  );

  return (
    <Select
      className="max-width-10 min-width-7"
      value={value}
      onChange={onChange as any}
      options={mapToSelectOptions(data?.results)}
      loading={isLoading}
      mode="multiple"
      placeholder="Subcategory"
    >
      <Select.Pagination
        count={data?.count || 0}
        limit={filters.limit}
        page={filters.page}
        setPage={(page) => setFilters({ page })}
        mode="scroll"
      />
    </Select>
  );
};
