import React from "react";
import { useSetState } from "react-use";
import { useQuery } from "react-query";

import { Select, useNotify } from "ebs-design";
import { ComponentProps as SelectProps } from "ebs-design/dist/components/molecules/Select/components";

import { brands } from "api";
import { defaultFilters, mapToSelectOptions, notifyErrors } from "utils";

export const BrandsSelect = ({ ...props }: SelectProps) => {
  const notify = useNotify();

  const [search, setSearch] = React.useState("");
  const [filters, setFilters] = useSetState(defaultFilters);

  const brandsQueryParams = { search, ...filters };

  const { data, isLoading } = useQuery(
    ["brands", brandsQueryParams],
    () => brands.getList(brandsQueryParams),
    { onError: (error) => notifyErrors(notify, error) },
  );

  return (
    <Select
      loading={isLoading}
      options={mapToSelectOptions(data?.results)}
      {...props}
    >
      <Select.Search
        placeholder="Search in brands"
        value={search}
        onSearch={setSearch}
      />

      <Select.Pagination
        count={data?.count || 0}
        limit={filters.limit}
        page={filters.page}
        setPage={(page) => setFilters({ page })}
        mode="scroll"
      />
    </Select>
  );
};
