import React from "react";
import { useQuery } from "react-query";
import { useSetState } from "react-use";
import { AxiosError } from "axios";

import { brands } from "api";
import { ActiveFiltersChangeHandler, useFilters } from "hooks";
import { BrandEntity, FiltersSource, FilterType, Results } from "types";
import { defaultFilters, mapToSelectOptions } from "utils";

export function useProductsFilters(
  onActiveFiltersChange: ActiveFiltersChangeHandler,
  { isBusiness = false },
) {
  const [search, setSearch] = React.useState("");
  const [brandFilters, setBrandFilters] = useSetState(defaultFilters);

  const queryBrandParams = { search, ...brandFilters };

  const brandsQuery = useQuery<Results<BrandEntity>, AxiosError>(
    ["brands", queryBrandParams],
    () => brands.getList(queryBrandParams),
  );

  const useFiltersResult = useFilters({
    filters: React.useMemo(
      (): FiltersSource => [
        {
          key: "brand_id",
          name: "Brand",
          type: FilterType.enum,
          values: mapToSelectOptions(brandsQuery.data?.results) || [],
          isLoading: brandsQuery.isLoading,
          filters: brandFilters,
          count: brandsQuery?.data?.count || 0,
          setFilters: (page: number) => setBrandFilters({ page }),
          onSearch: setSearch,
        },
        {
          key: "price__range",
          name: "Price",
          type: FilterType.numberRange,
          min: 0,
        },
        {
          key: "is_active",
          name: "Is active",
          type: FilterType.boolean,
        },
        ...(isBusiness
          ? []
          : ([
              {
                key: "featured",
                name: "Featured",
                type: FilterType.boolean,
              },
              {
                key: "in_gamification",
                name: "Referral",
                type: FilterType.boolean,
              },
              {
                key: "is_free",
                name: "Free",
                type: FilterType.boolean,
              },
              {
                key: "in_main_catalog",
                name: "In main catalog",
                type: FilterType.boolean,
              },
            ] as const)),
      ],
      [brandsQuery, isBusiness, brandFilters, setBrandFilters],
    ),
    onActiveFiltersChange,
  });

  return {
    errors: [brandsQuery.error],
    useFiltersResult,
  };
}
